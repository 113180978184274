import "../styles/globals.css";
import { AuthProvider } from "components/contexts/AuthContext";
import { CacheProvider } from "components/contexts/CacheContext";
import { CsrfProvider } from "components/contexts/CsrfContext";
import { useRouter } from "next/router";
import React from "react";
import { AnalyticsProvider } from "../components/contexts/AnalyticsProvider";
import NewLayout from "components/layout/NewLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "jotai";
import BackToTop from "components/layout/BackToTop";
import { GoogleOAuthProvider } from "@react-oauth/google";
import HelpBox from "components/layout/HelpBox";
import SEOHead from "components/seo/SEOHead";

export default function App({ Component, pageProps }) {
	const router = useRouter();

	const getLayoutGlobal = () => {
		if (
			router.asPath.startsWith("/product") ||
			router.asPath.startsWith("/store") ||
			router.asPath.startsWith("/cities") ||
			router.asPath.startsWith("/manufacturer") ||
			router.asPath.startsWith("/marketer") ||
			router.asPath.startsWith("/checkout") ||
			router.asPath.startsWith("/terms") ||
			router.asPath.startsWith("/accessibility") ||
			router.asPath.startsWith("/privacy") ||
			router.asPath.startsWith("/account") ||
			router.asPath.startsWith("/strain") ||
			router.asPath.startsWith("/cart") ||
			router.asPath.startsWith("/login") ||
			router.asPath.startsWith("/signup") ||
			router.asPath.startsWith("/desired-cart") ||
			router.asPath.startsWith("/categories") ||
			router.asPath.startsWith("/batches") ||
			router.asPath.startsWith("/feed")
		) {
			return NewLayout;
		}
		return React.Fragment;
	};

	const LayoutProvider = getLayoutGlobal();

	const getLayout = Component.getLayout || (page => page);
	return (
		<>
			<SEOHead pageProps={pageProps} />
			<noscript>
				<iframe
					src="https://www.googletagmanager.com/ns.html?id=GTM-M4PKQWZF"
					height="0"
					width="0"
					style={{ display: "none", visibility: "hidden" }}
				></iframe>
			</noscript>
			<CsrfProvider>
				<CacheProvider>
					<GoogleOAuthProvider clientId="826223448842-tlkhl5ui7rklibccmau63uu3vq1k4j4a.apps.googleusercontent.com">
						<AuthProvider>
							<AnalyticsProvider>
								<Provider>
									<LayoutProvider>
										<ToastContainer />
										<BackToTop />
										<HelpBox />
										{getLayout(<Component {...pageProps} />)}
									</LayoutProvider>
								</Provider>
							</AnalyticsProvider>
						</AuthProvider>
					</GoogleOAuthProvider>
				</CacheProvider>
			</CsrfProvider>
		</>
	);
}
