import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import EmailField from "components/fields/EmailField";
import IdField from "components/fields/IdField";
import NameField from "components/fields/NameField";
import { PhoneField } from "components/fields/PhoneField";
import { Button } from "components/form/Button";
import Link from "next/link";
import React from "react";
import { FaSpinner } from "react-icons/fa";
import { toastMessage } from "util/common";

function RegisterStep({
	idNumber,
	registerUser,
	registerForm,
	loading,
	loginForm,
	requestRegisterOtp,
	checkAvailability,
}) {
	const {
		register,
		watch,
		trigger,
		handleSubmit,
		formState: { errors, isValid, isDirty, isSubmitting },
	} = registerForm;

	const onSubmit = async params => {
		const userInfo = {
			id_number: params.id_number,
			email: params.email,
			prefix_phone: params.prefix_phone,
			phone: params.phone,
			first_name: params.first_name,
			last_name: params.last_name,
		};
		registerForm.setValue("id_number", params.id_number);
		registerForm.setValue("first_name", params.first_name);
		registerForm.setValue("last_name", params.last_name);
		registerForm.setValue("prefix_phone", params.prefix_phone);
		registerForm.setValue("phone", params.phone);
		registerForm.setValue("email", params.email);
		const isAvailable = await checkAvailability({
			email: params.email,
			phone_number: params.prefix_phone + "-" + params.phone,
		});
		if (isAvailable) {
			requestRegisterOtp(userInfo);
		} else {
			toastMessage("אחד מהפרטים כבר קיימים במערכת", "error");
		}
	};

	return (
		<div className="rounded-md bg-white flex flex-col gap-4 px-6 py-6 md:px-24 md:py-14">
			<div className="flex justify-between items-center w-full text-jane-500">
				<span className="flex flex-col gap-1">
					<h2 className="text-3xl leading-7 font-semibold">הרשמה</h2>
					<p className="text-jane-900 leading-4">
						בשביל להמשיך בתהליך יש להזין את הפרטים הבאים:
					</p>
				</span>
				<QuestionMarkCircleIcon className="w-24 h-24" />
			</div>
			<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
				<div className="flex gap-2 w-full">
					<NameField register={register} errors={errors} className="w-full" />
					<NameField
						register={register}
						label="שם משפחה"
						placeholder="שם משפחה"
						registerName="last_name"
						name="lastName"
						errors={errors}
						className="w-full"
					/>
				</div>
				<IdField errors={errors} register={register} disabled />
				<PhoneField
					label="מספר טלפון"
					register={register}
					trigger={trigger}
					required
					errors={errors}
				/>
				<EmailField
					required
					register={register}
					registerName="email"
					errors={errors}
					placeholder="כתובת אי-מייל"
				/>
				<EmailField
					required
					register={register}
					registerName="email_confirm"
					errors={errors}
					placeholder="אישור כתובת אי-מייל"
					additionalValidation={{
						validate: value => {
							return value === watch("email") || "כתובות אי-מייל אינן תואמות";
						},
					}}
				/>

				<span className="flex items-center gap-2">
					<input
						type="checkbox"
						id="agreement"
						{...register("terms", {
							required: true,
						})}
					/>
					<label
						htmlFor="agreement"
						className={`transition-colors ${
							errors?.terms ? "text-red-500" : "text-jane-500"
						}`}
					>
						אני מאשר/ת כי קראתי והבנתי את{" "}
						<Link href={"/terms"}>
							<a
								className="underline"
								target="_blank"
								rel="noopener noreferrer"
							>
								תנאי השימוש באתר
							</a>
						</Link>
					</label>
				</span>

				<Button primary type="submit" disabled={loading}>
					<span className="text-base p-1 md:p-0 flex items-center gap-2">
						{loading && <FaSpinner className="animate-spin h-5 w-5" />}
						{loading ? "טוען..." : "הרשמה"}
					</span>
				</Button>
			</form>
		</div>
	);
}

export default RegisterStep;
