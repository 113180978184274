import { useContext, useEffect, useState } from "react";
import { useApi } from "./useApi";
import { toastMessage } from "util/common";
import { AnalyticsContext } from "components/contexts/AnalyticsProvider";
import { AuthContext } from "components/contexts/AuthContext";
import { LOGIN_STEPS } from "util/types";
import { useForm } from "react-hook-form";

export function useLogin(onLogin) {
	const {
		LOGIN,
		CODE_LOGIN,
		CODE_VERIFY,
		NOT_FOUND,
		REGISTER,
		REGISTER_OTP,
		WELCOME,
	} = LOGIN_STEPS;
	const api = useApi();
	const { checkLogin } = useContext(AuthContext);
	const { collectAnalytics } = useContext(AnalyticsContext);
	const [step, setStep] = useState(LOGIN);
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [seconds, setSeconds] = useState(30);
	const [canSendAgain, setCanSendAgain] = useState(false);
	const [attempt, setAttempt] = useState(1);

	const loginForm = useForm({
		defaultValues: { id_number: "", otp_code: "", signin_method: "phone" },
	});

	const { getValues, setValue, watch, trigger, handleSubmit } = loginForm;
	const registerForm = useForm({
		mode: "onChange",
		defaultValues: { otp_code: "" },
	});

	const tokenLogin = async token => {
		try {
			setLoading(true);
			const res = await api.submitLoginWithToken({ token });
			if (res.ok) {
				setStep(CODE_LOGIN);
				setValue("id_number", res.data.user_id);
			} else {
				throw res.error.message;
			}
		} catch (error) {
			console.log(error);
			toastMessage("שגיאה בהתחברות", "error");
		} finally {
			setLoading(false);
		}
	};

	const idLogin = async (signin_method = "phone") => {
		setSeconds(30);
		setCanSendAgain(false);
		try {
			setLoading(true);
			const res = await api.submitLogin({
				id_number: getValues("id_number"),
				signin_method: getValues("signin_method"),
			});
			if (res.ok) {
				setErrorMessage("");
				setStep(CODE_LOGIN);
			} else {
				throw res.error;
			}
		} catch (error) {
			if (error.message === "המשתמש אינו קיים.") {
				registerForm.setValue("id_number", getValues("id_number"));
				setStep(REGISTER);
			} else {
				setErrorMessage(error.message);
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		let interval = null;
		if (!canSendAgain && seconds > 0) {
			interval = setInterval(() => {
				setSeconds(seconds => seconds - 1);
			}, 1000);
		} else if (seconds === 0) {
			clearInterval(interval);
			setCanSendAgain(true);
		}
		return () => clearInterval(interval);
	}, [canSendAgain, seconds]);

	const sendAgain = (mode = "login") => {
		if (getValues("signin_method") === "phone") {
			if (attempt <= 1) {
				if (mode === "login") {
					idLogin();
				} else {
					requestRegisterOtp({
						phone: registerForm.watch("phone"),
						first_name: registerForm.watch("first_name"),
						last_name: registerForm.watch("last_name"),
					});
				}
				setAttempt(attempt + 1);
				return;
			} else {
				setValue("signin_method", "whatsapp");
				setAttempt(1);
				if (mode === "login") {
					idLogin();
				} else {
					requestRegisterOtp({
						phone: registerForm.watch("phone"),
						first_name: registerForm.watch("first_name"),
						last_name: registerForm.watch("last_name"),
					});
				}
				return;
			}
		}
		if (getValues("signin_method") === "whatsapp") {
			if (attempt <= 2) {
				if (mode === "login") {
					idLogin();
				} else {
					requestRegisterOtp({
						phone: registerForm.watch("phone"),
						first_name: registerForm.watch("first_name"),
						last_name: registerForm.watch("last_name"),
					});
				}
				setAttempt(attempt + 1);
				return;
			}
		}
	};

	const requestRegisterOtp = async params => {
		setLoading(true);
		const name = `${params.first_name} ${params.last_name}`;
		const phone = params.prefix_phone + "-" + params.phone;
		const res = await api.requestOtp({
			name,
			phone_number: phone,
		});
		if (res.ok) {
			setStep(REGISTER_OTP);
			setSeconds(30);
			setCanSendAgain(false);
			if (res.data.sent_via === "phone") {
				toastMessage("קוד חד פעמי נשלח ב-SMS", "success");
			}
		} else {
			toastMessage(res.error.message, "error");
		}
		setLoading(false);
	};

	const registerUser = async params => {
		//*   params config:
		//    params: {
		//    id_number: "",
		//    email: "",
		//    phone_number: "",
		//		first_name: "",
		//		last_name: "",
		// }

		setLoading(true);
		const phone =
			registerForm.watch("prefix_phone") + "-" + registerForm.watch("phone");
		const otpRes = await api.verifyOtp({
			otp_code: registerForm.watch("otp_code"),
			phone_number: phone,
		});
		if (otpRes.ok) {
			const res = await api.submitSignup({
				...registerForm.getValues(),
				phone: phone,
			});
			// checkAvailability
			if (res.ok) {
				setAttempt(0);
				toastMessage("הרשמה בוצעה בהצלחה", "success");
				await checkLogin();
				if (typeof onLogin === "function") {
					await onLogin();
				}
			} else {
				setStep(REGISTER);
				registerForm.setValue("otp_code", "");
				toastMessage(res.error.message, "error");
			}
		} else {
			toastMessage(otpRes.error.message, "error");
		}
		setLoading(false);
	};

	const checkAvailability = async params => {
		//* params config:
		//  params:{
		//      email: "",
		//      phone_number: "",
		//  }
		const res = await api.checkAvailability(params);
		if (res.ok && !res.data.end_user_exists) {
			//available
			return true;
		} else {
			//not available
			return false;
		}
	};

	const verifyCodeLogin = async () => {
		// params conf:
		//     params: {
		//         otp_code: "",
		//         id_number: "",
		//     }

		try {
			setLoading(true);
			const res = await api.submitLoginOtp({
				otp_code: getValues("otp_code"),
				id_number: getValues("id_number"),
			});
			if (res.ok) {
				toastMessage(`התחברת לחשבונך בהצלחה`, "success");
				collectAnalytics("end_user_login", {
					method: "otp",
					otp_code: getValues("otp_code"),
					id_number: getValues("id_number"),
				});
				await checkLogin();
				if (typeof onLogin === "function") {
					await onLogin();
				}
			} else {
				throw res.error;
			}
		} catch (error) {
			toastMessage(error.message, "error");
		} finally {
			setLoading(false);
		}
	};

	const onKeyDown = e => {
		if (e.key === "Enter") {
			e.preventDefault();
			if (step === LOGIN) {
				handleSubmit(idLogin)();
				return;
			}
			if (step === CODE_LOGIN) {
				verifyCodeLogin();
				return;
			}
			if (step === REGISTER_OTP) {
				handleSubmit(registerUser)();
			}
		}
	};

	useEffect(() => {
		document.addEventListener("keydown", onKeyDown);
		return () => {
			document.removeEventListener("keydown", onKeyDown);
		};
	}, [step]);

	return {
		step,
		loading,
		seconds,
		canSendAgain,
		setLoading,
		setStep,
		loginForm,
		registerForm,
		idLogin,
		tokenLogin,
		registerUser,
		verifyCodeLogin,
		sendAgain,
		attempt,
		requestRegisterOtp,
		checkAvailability,
		//
		errorMessage,
		setErrorMessage,
		onLogin,
	};
}

//******************* DEAD CODE DO NOT DELETE *******************/
//!!!!!!!!!!!!!!!!! we will need this for new design  !!!!!!!!!!!!!!!!!/

//	const [userDetails, setUserDetails] = useState({});
//	const { authenticated, checkLogin, lastPath } = useContext(AuthContext);

// const verifyPhoneRequest = async params => {
// 	//* params config:
// 	//  params:{
// 	//      phone_number: ""
// 	//  }
// 	try {
// 		const res = await api.otpPhoneVerify(params);
// 		if (res.ok) {
// 			setStep(CODE_VERIFY);
// 			toastMessage("קוד אימות נשלח לטלפון שלך", "success");
// 			return;
// 		} else {
// 			throw res.error;
// 		}
// 	} catch (error) {}
// };

// const verifyPhoneRegister = async params => {
// 	//  params:{
// 	//      phone_number: ""
// 	//      otp_code: ""
// 	//  }
// 	try {
// 		const res = await api.otpVerifyPhoneSubmit(params);
// 		if (res.ok) {
// 			toastMessage("Phone verified!", "success");
// 			await registerUser(userInfo);
// 		} else {
// 			throw res.error;
// 		}
// 	} catch (error) {
// 		toastMessage(error.message, "error");
// 	}
// };

// const startRegisterProcess = async params => {
// 	//*   params config:
// 	//    params: {
// 	//    id_number: "",
// 	//    email: "",
// 	//    phone_number: "",
// 	// }
// 	setUserDetails(params);
// 	try {
// 		const isAvailable = await checkAvailability(params);
// 		if (isAvailable) {
// 			await verifyPhoneRequest(params.phone_number);
// 		} else {
// 			throw { message: "אחד מהפרטים שהוזנו כבר בשימוש" };
// 		}
// 	} catch (error) {
// 		if (error.message === "אחד מהפרטים שהוזנו כבר בשימוש") {
// 			toastMessage(error.message, "error");
// 		}
// 		console.log(error.message);
// 	} finally {
// 		setLoading(false);
// 	}
// };
