import { PhotographIcon } from "@heroicons/react/outline";
import { AnalyticsContext } from "components/contexts/AnalyticsProvider";
import { ProductTypeUI, VARIANT } from "components/products/ProductTypeUI";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import { listProductTypes } from "util/types";
import ProductImage from "../ProductImage";
import {
	ProductCornerBadge,
	ProductOutOfStockBadge,
} from "components/products/ProductBadges";
import { isComingSoon, isNewInStore } from "util/date";
import moment from "moment";
import { InformationCircleIcon } from "@heroicons/react/outline";

const ResultRow = ({
	link,
	handleClick,
	title,
	subtitle,
	imgUrl,
	noImage,
	children,
}) => {
	const router = useRouter();
	const basePath = router.pathname.replace(/\[.*\]$/, "");
	const isTheSamePage = link.startsWith(basePath);

	const content = children || (
		<div className="flex justify-between items-center">
			<div className="flex gap-2 items-center">
				{!noImage &&
					(imgUrl ? (
						<img src={imgUrl} className="w-10 h-10 rounded" alt={title} />
					) : (
						<PhotographIcon className="w-10 h-10 text-jane-400 rounded" />
					))}
				<div className="flex flex-col">
					<div className="text-jane-800 text-lg leading-5 font-semibold">
						{title}
					</div>
					<div className="text-jane-400">{subtitle}</div>
				</div>
			</div>
		</div>
	);

	if (isTheSamePage) {
		return (
			<a
				href={link}
				onClick={handleClick}
				className="bg-white py-1 transition-all hover:bg-jane-200"
			>
				{content}
			</a>
		);
	}

	return (
		<Link href={link} passHref>
			<a
				onClick={handleClick}
				className="bg-white py-1 transition-all hover:bg-jane-200"
			>
				{content}
			</a>
		</Link>
	);
};

// Helper function to render product results
const renderProductResult = (item, onResultClick, collectAnalytics) => (
	<ResultRow
		key={`product-${item.id}`}
		link={`/products/${item.id}`}
		handleClick={() => {
			collectAnalytics("searchbar_product", {
				product_id: item.id,
			});
			onResultClick();
		}}
	>
		<div className="flex justify-between items-center relative overflow-hidden px-2">
			<div className="flex gap-2 items-center">
				{!item.store_add_datetime ? (
					isComingSoon(item.created_at) ? (
						<ProductCornerBadge
							variant="searchBar"
							color="bg-[#0F85D7]"
							text="בקרוב"
						/>
					) : null
				) : !item.in_stock ? null : isNewInStore(item.store_add_datetime) ? (
					<ProductCornerBadge
						variant="searchBar"
						color="bg-green-800"
						text="חדש!"
					/>
				) : null}
				<span className="relative">
					{!item.in_stock &&
						(item.store_add_datetime || !isComingSoon(item.created_at)) && (
							<ProductOutOfStockBadge variant="searchBar" />
						)}
					<ProductImage
						product={item}
						src={item?.main_img_thumbnail_url}
						className="w-10 h-10 rounded"
					/>
				</span>
				<div className="flex flex-col">
					<div className="text-jane-800 text-lg leading-5 font-semibold">
						{item?.heb_name}
					</div>
					<div className="text-jane-400">{item?.marketer_heb_name}</div>
				</div>
			</div>
			<div className="flex gap-0.5">
				<ProductTypeUI
					productType={listProductTypes.find(productType => {
						return productType.label === item.product_type;
					})}
					variant={VARIANT.SMALLTEXT}
				/>
				<ProductTypeUI
					productType={listProductTypes.find(productType => {
						return productType.label === item.category;
					})}
					variant={VARIANT.SMALLTEXT}
				/>
				<ProductTypeUI
					productType={listProductTypes.find(productType => {
						return productType.label === item.family;
					})}
					variant={VARIANT.SMALLTEXT}
				/>
			</div>
		</div>
	</ResultRow>
);

// Helper function to render city results
const renderCityResult = (item, onResultClick, collectAnalytics) => (
	<ResultRow
		key={`city-${item.id}`}
		link={`/cities/${
			!!item?.display_name_eng ? item.display_name_eng : item.id
		}`}
		handleClick={() => {
			collectAnalytics("searchbar_city", {
				city_id: item.id,
			});
			onResultClick();
		}}
		noImage
		title={item?.heb_name}
		subtitle={item?.eng_name}
	/>
);

// Helper function to render store results
const renderStoreResult = (item, onResultClick, collectAnalytics) => (
	<ResultRow
		key={`store-${item.id}`}
		link={`/store/${
			!!item?.display_name_eng ? item.display_name_eng : item.id
		}`}
		handleClick={() => {
			collectAnalytics("searchbar_store", {
				store_id: item.id,
			});
			onResultClick();
		}}
		imgUrl={item?.logo_thumbnail_url || item?.logo_url}
		title={item?.display_name}
		subtitle={
			moment(item.stock_updated_at).isBefore(moment().subtract(72, "hours")) ? (
				<div className="text-yellow-500 font-semibold flex items-center gap-1 text-xs">
					<InformationCircleIcon className="w-3.5 h-3.5" />
					מלאי לא עודכן
				</div>
			) : (
				item?.city?.heb_name
			)
		}
	/>
);

// Helper function to render marketer results
const renderMarketerResult = (item, onResultClick, collectAnalytics) => (
	<ResultRow
		key={`marketer-${item.id}`}
		link={`/marketer/${item.id}`}
		handleClick={() => {
			collectAnalytics("searchbar_marketer", {
				marketer_id: item.id,
			});
			onResultClick();
		}}
		imgUrl={item?.logo_url}
		title={item?.heb_name}
		subtitle={item?.eng_name}
	/>
);

// Helper function to render manufacturer results
const renderManufacturerResult = (item, onResultClick, collectAnalytics) => (
	<ResultRow
		key={`manufacturer-${item.id}`}
		link={`/manufacturer/${item.id}`}
		handleClick={() => {
			collectAnalytics("searchbar_manufacturer", {
				manufacturer: item.id,
			});
			onResultClick();
		}}
		imgUrl={item?.logo_url}
		title={item?.heb_name}
		subtitle={item?.eng_name}
	/>
);

// Helper function to render strain results
const renderStrainResult = (item, onResultClick, collectAnalytics) => (
	<ResultRow
		key={`strain-${item.id}`}
		link={`/strains/${item.id}`}
		handleClick={() => {
			collectAnalytics("searchbar_strain", {
				strain_id: item.id,
			});
			onResultClick();
		}}
		noImage
		title={item?.heb_name}
		subtitle={item?.eng_name}
	/>
);

// Helper function to render product series results
const renderProductSeriesResult = (item, onResultClick, collectAnalytics) => (
	<ResultRow
		key={`product-series-${item.id}`}
		link={`/products/?filters=series_id^in^${item.id}`}
		handleClick={() => {
			collectAnalytics("searchbar_product_series", {
				series_id: item.id,
			});
			onResultClick();
		}}
		noImage
		title={item?.heb_name}
		subtitle={item?.eng_name}
	/>
);

// Helper function to render manufacturer series results
const renderManufacturerSeriesResult = (
	item,
	onResultClick,
	collectAnalytics,
) => (
	<ResultRow
		key={`manufacturer-series-${item.id}`}
		link={`/products/?filters=manufacturer_series_id^in^${item.id}`}
		handleClick={() => {
			collectAnalytics("searchbar_manufacturer_series", {
				brand_id: item.id,
			});
			onResultClick();
		}}
		noImage
		title={item?.heb_name}
		subtitle={item?.eng_name}
	/>
);

// Category labels for section headers
const CATEGORY_LABELS = {
	products: "מוצרים",
	stores: "בתי מרקחת",
	cities: "ערים",
	strains: "גנטיקות",
	marketers: "משווקים",
	manufacturers: "מגדלים",
	product_series: "סדרות",
	manufacturer_series: "מותגים",
};

function GlobalSearchResults({ onResultClick, results = {} }) {
	const { collectAnalytics } = useContext(AnalyticsContext);

	// Check if there are any results across all categories
	const hasResults = Object.values(results).some(
		categoryResults =>
			Array.isArray(categoryResults.items) && categoryResults.items.length > 0,
	);

	if (!hasResults) {
		return <p className="text-center p-4 text-lg">אין תוצאות חיפוש</p>;
	}

	// Render function map for each category
	const renderFunctions = {
		products: item =>
			renderProductResult(item, onResultClick, collectAnalytics),
		cities: item => renderCityResult(item, onResultClick, collectAnalytics),
		stores: item => renderStoreResult(item, onResultClick, collectAnalytics),
		marketers: item =>
			renderMarketerResult(item, onResultClick, collectAnalytics),
		manufacturers: item =>
			renderManufacturerResult(item, onResultClick, collectAnalytics),
		strains: item => renderStrainResult(item, onResultClick, collectAnalytics),
		product_series: item =>
			renderProductSeriesResult(item, onResultClick, collectAnalytics),
		manufacturer_series: item =>
			renderManufacturerSeriesResult(item, onResultClick, collectAnalytics),
	};

	return (
		<div className="flex gap-1 py-2 flex-col">
			{Object.entries(results).map(([category, categoryResults]) => {
				// Skip empty categories
				if (
					!Array.isArray(categoryResults.items) ||
					categoryResults.items.length === 0
				) {
					return null;
				}

				return (
					<div key={category} className="flex flex-col">
						<div className="bg-jane-100 px-2 py-1 font-medium text-jane-800">
							{CATEGORY_LABELS[category] || category}
						</div>
						{categoryResults.items.map(item => renderFunctions[category](item))}
					</div>
				);
			})}
		</div>
	);
}

export default GlobalSearchResults;
