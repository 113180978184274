import { InformationCircleIcon } from "@heroicons/react/outline";
import { Button } from "components/form/Button";
import React from "react";
import { FaSpinner } from "react-icons/fa";
import { MdOutlinePassword } from "react-icons/md";
import VerificationInput from "react-verification-input";
import { classNames } from "util/common";
import { LOGIN_STEPS } from "util/types";

function RegisterOtpStep({
	verifyCodeLogin,
	loading = false,
	//idNumber: id_number, //! for dead code below
	seconds,
	sendAgain,
	canSendAgain,
	loginForm,
	registerForm,
	registerUser,
	attempt,
	setStep,
	errorMessage,
}) {
	const { REGISTER } = LOGIN_STEPS;
	const signinMethod = loginForm.watch("signin_method");

	return (
		<div className="rounded-md bg-white flex flex-col gap-4  p-6 md:px-24 md:py-14">
			<div className="flex items-center text-jane-500 justify-between w-full">
				<h2 className="text-3xl leading-7 font-semibold">אימות טלפון</h2>
				<MdOutlinePassword className="h-12 w-12" />
			</div>
			<p>
				<span>
					{signinMethod === "phone" && attempt === 1
						? "קוד חד פעמי נשלח ב-SMS"
						: signinMethod === "phone" && attempt > 1
						? "שלחנו קוד נוסף ב-SMS"
						: signinMethod === "whatsapp" && attempt === 1
						? "שלחנו קוד נוסף, הפעם ב-WhatsApp"
						: signinMethod === "whatsapp" && attempt > 1
						? "ופעם נוספת ואחרונה ב-WhatsApp"
						: "קוד חד פעמי נשלח ב-SMS"}
				</span>
			</p>
			<VerificationInput
				autoFocus
				inputProps={{
					autocomplete: "one-time-code",
					inputMode: "numeric",
					type: "tel",
				}}
				validChars="0-9"
				placeholder=""
				onChange={code => registerForm.setValue("otp_code", code)}
				classNames={{
					container: "flex-row-reverse w-full gap-4 rounded-md",
					character: "rounded-md text-center border-jane-300",
					characterInactive: "bg-jane-200",
					characterSelected: "outline-jane-500",
					characterFilled: "text-[24px]",
				}}
			/>
			{signinMethod === "whatsapp" && attempt >= 2 ? (
				<span className="flex justify-between gap-2 items-center">
					<a
						href="https://wa.link/llbvxj"
						target="_blank"
						rel="noreferrer"
						className={classNames(
							"underline",
							"text-red-500 cursor-pointer select-none",
						)}
					>
						שוב לא מצליח, תעזרו לי.
					</a>
				</span>
			) : (
				<span className="flex justify-between gap-2 items-center">
					<span
						onClick={() => canSendAgain && sendAgain("register")}
						className={classNames(
							"underline",
							canSendAgain
								? "text-jane-500 cursor-pointer select-none"
								: "text-jane-400 cursor-default select-none",
						)}
					>
						{signinMethod === "phone" && attempt === 1
							? "לא קיבלתי, שלחו שוב"
							: "לא קיבלתי, שלחו שוב ב-WhatsApp"}
					</span>
					<span>00:{String(seconds).padStart(2, "0")}</span>
				</span>
			)}
			<Button
				primary
				disabled={loading || registerForm.watch("otp_code").length < 6}
				onClick={() => {
					registerUser();
				}}
			>
				<span className="p-1 text-base flex items-center gap-2 md:p-0">
					{loading && <FaSpinner className="animate-spin h-5 w-5" />}
					{loading ? "טוען..." : "המשך"}
				</span>
			</Button>
			<Button
				disabled={loading}
				onClick={() => {
					setStep(REGISTER);
				}}
			>
				<span className="p-1 text-base flex items-center gap-2 md:p-0">
					חזרה
				</span>
			</Button>
			{!!errorMessage && (
				<div className="border p-2 rounded-md flex items-center gap-2 text-red-900 fond-semibold border-red-400 bg-red-50">
					<InformationCircleIcon className="h-4 w-4" />
					{errorMessage}
				</div>
			)}
		</div>
	);
}

export default RegisterOtpStep;

//******************* DEAD CODE DO NOT DELETE *******************/
//!!!!!!!!!!!!!!!!! we will need this for new design  !!!!!!!!!!!!!!!!!/
{
	/* <Button
	primary
	disabled={loading || code.length < 6}
	onClick={() => {
		if (!isRegisteration && !!id_number) {
			verifyCodeLogin({ otp_code: code, id_number: id_number });
		} else if (!!isRegisteration) {
			verifyPhoneRegister({ otp_code: code });
		}
	}}
>
	{loading ? "טוען..." : "המשך"}
</Button>; */
}
